'use strict';

angular.module('kljDigitalLibraryApp')
    .service('IndexService', ["$q", "common", "appConfig",
        function($q, common, appConfig) {

            var baseUrl = '/api/user_subscription/';
            var list;
            this.userAddress = undefined;
            this.storeList = function(data) {
                list = data;
            }

            this.planInfoList = function() {
                var arr = [],
                    current = list ? list.currentSubscriptions : [],
                    expired = list ? list.expiredSubscriptions : [];
                if (current.length > 0) {
                    for (var i = 0; i < current.length; i++) {
                        if (arr.indexOf(current[i].category) === -1) {
                            arr.push(current[i].category);
                        }
                    }
                }
                if (expired.length > 0) {
                    for (var i = 0; i < expired.length; i++) {
                        if (arr.indexOf(expired[i].category) === -1) {
                            arr.push(expired[i].category);
                        }
                    }
                }
                return arr;
            }

            this.currentSubscriptionsList = function() {
                var arr = [],
                    current = list ? list.currentSubscriptions : [];
                if (current.length > 0) {
                    for (var i = 0; i < current.length; i++) {
                        if (arr.indexOf(current[i].category) === -1) {
                            arr[i] = current[i];
                        }
                    }
                }
                return arr;
            }

            this.getList = function() {
                return list;
            }

            this.isTrial = function(name) {
                let istrial = false;
                var current = list ? list.currentSubscriptions : [];
                
                if (current.length > 0) {
                    for (var i = 0; i < current.length; i++) {
                        if (current[i].category === name && current[i].plan_type === 'Regular') {
                            return false;
                        }
                        if (current[i].category === name && current[i].plan_type === 'Trial') {
                            istrial = true;
                        }
                    }
                    if(i === current.length) return istrial;
                    
                } else {
                    return istrial;
                }
            }

            this.getCurrentList = function() {

                var deferred = $q.defer();

                common.callApi('GET',
                    baseUrl + 'currentSubscriptions',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE)

                .then(function(response) {

                    deferred.resolve(response.data);
                })

                .catch(function(error) {

                    deferred.reject(error);
                });

                return deferred.promise;
            }

            this.getCurrentListCategoryDetails = function(list) {

                var deferred = $q.defer();

                common.callApi('POST',
                    baseUrl + 'getCurrentListCategory',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,
                    list)

                .then(function(response) {

                    deferred.resolve(response.data);
                })

                .catch(function(error) {

                    deferred.reject(error);
                });

                return deferred.promise;
            }


            this.saveHistory = function(body) {
                var deferred = $q.defer();
                var url = '/api/users/saveHistory';
                common.callApi('POST',
                        url,
                        appConfig.EMPTY_STRING,
                        appConfig.CONTENT_TYPE,
                        body
                    )
                    .then(function(response) {
                        deferred.resolve(response.data);
                    })
                    .catch(function(error) {
                        deferred.reject(error);
                    });

                return deferred.promise;
            }
            this.appTitle = function() {
                var deferred = $q.defer();
                common.callApi('GET',
                    baseUrl + 'getAppTitle',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE)

                .then(function(response) {
                    deferred.resolve(response);
                })

                .catch(function(error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            }
            this.verifyDongle = function() {
                var deferred = $q.defer();

                common.callApi('GET',
                    baseUrl + 'verifyDongle',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE)

                .then(function(response) {
                    deferred.resolve(response.data);
                })

                .catch(function(error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            }
        }
    ]);